<template>
  <div class="container" style="margin-top: 20vh;max-width: 300px">
    <b-form @submit="onSubmit">
      <b-form-group label="Нэвтрэх">
        <b-form-input
          class="mb-1"
          v-model="form.email"
          placeholder="Нэвтрэх нэр"
        ></b-form-input>
        <b-form-input
          v-model="form.password"
          type="password"
          placeholder="Нууц үг"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Нэвтрэх</b-button>
    </b-form>

  </div>
</template>

<script>

export default {
  name: "Home",
  components: {
    
  },
  data(){
    return{
      form: {
        email: '',
        password: ''
      },
    }
  },
  methods:{
    onSubmit(){
      console.log('sdf');
      this.axios({
        url: 'https://smsapi.gosmart.mn/core/user/loginApi',
        method: 'POST',
        data:{
          user: this.form.email,
          password: this.form.password
        }
      }).then((res) => {
        if(res.data.code==200){
          this.$cookie.set('token', res.data.token, 1);
          this.$router.push({name: 'list'});
        }else{
          this.$bvToast.toast(`Нэр нууц үг буруу байна`, {
            title: 'Мэдэгдэл',
            autoHideDelay: 5000,
          })
          console.log('alert', res.data)
        }
      })
    },
    onReset(){}
  }
};
</script>
