import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
